import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
import './SelectCenter.css';

function SelectCenter({ centersList, onSelectedCentersChange }) {
  const [selectedCenters, setSelectedCenters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 5;

  /*useEffect(() => {
    if (centersList && centersList.length > 0) {
      setSelectedCenters(centersList.map((center) => center.id));
    }
  }, [centersList]);*/

  useEffect(() => {
    console.log('Centros seleccionados actualizados:', selectedCenters);
    onSelectedCentersChange(selectedCenters); // Actualiza el estado en App.js
  }, [selectedCenters, onSelectedCentersChange]);


  const filteredCenters = centersList.filter((center) =>
    center.center_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCenters.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCenterSelect = (centerId) => {
    setSelectedCenters((prevSelectedCenters) =>
      prevSelectedCenters.includes(centerId)
        ? prevSelectedCenters.filter((id) => id !== centerId)
        : [...prevSelectedCenters, centerId]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const toggleSelectAll = () => {
    if (selectedCenters.length === filteredCenters.length) {
      setSelectedCenters([]);
    } else {
      setSelectedCenters(filteredCenters.map((center) => center.id));
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedCenters = filteredCenters.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="select-center">
      <h3>📚 Listado de centros de la comunidad elegida:</h3>
      <input
        type="text"
        placeholder="Buscar centro..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />

      <button className="select-all-button" onClick={toggleSelectAll}>
        {selectedCenters.length === filteredCenters.length ? 'Deseleccionar todo' : 'Seleccionar todo'}
      </button>

      <ul className="center-list">
        {paginatedCenters.length > 0 ? (
          paginatedCenters.map((center) => (
            <li key={center.id} className="center-item">
              <label>
                <input
                  type="checkbox"
                  checked={selectedCenters.includes(center.id)}
                  onChange={() => handleCenterSelect(center.id)}
                />
                {center.center_name}
              </label>
            </li>
          ))
        ) : (
          <p>No se encontraron centros educativos</p>
        )}
      </ul>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default SelectCenter;
