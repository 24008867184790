import React, { useState, useEffect } from 'react';
import './PendingOrders.css';

function PendingOrders({ API_URL, handleOrderSelection }) {
  const [pendingOrders, setPendingOrders] = useState([]); // Estado para los pedidos pendientes
  const [selectedOrder, setSelectedOrder] = useState(null); // Estado para el pedido seleccionado
  const [error, setError] = useState(''); // Estado para manejar errores
  const [loading, setLoading] = useState(true); // Estado para el indicador de carga

  // Función para obtener pedidos pendientes desde la API
  const getPendingOrders = async () => {
    try {
      const token = localStorage.getItem('sessionToken'); // Obtén el token directamente de localStorage
      console.log('Token obtenido:', token);

      if (!token) {
        throw new Error('No hay un token disponible. Por favor, inicia sesión.');
      }

      const url = `${API_URL}/api/orders/pending`;
      console.log('URL construida:', url);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Verifica si la respuesta es válida y contiene JSON
      const contentType = response.headers.get('Content-Type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.log('Respuesta no JSON recibida:', text);
        throw new Error(`Respuesta inesperada del servidor: ${text}`);
      }

      const data = await response.json();
      console.log('Datos obtenidos de la API:', data);

      if (response.ok) {
        setPendingOrders(data.data); // Almacena los pedidos pendientes
        console.log('Pedidos pendientes almacenados:', data.data);
      } else {
        throw new Error(data.error || 'Error al obtener los pedidos pendientes');
      }
    } catch (err) {
      console.error('Error al obtener los pedidos pendientes:', err.message);
      setError(err.message); // Almacena el mensaje de error en el estado
    } finally {
      setLoading(false); // Desactiva el indicador de carga
    }
  };

  // useEffect para llamar a la API cuando se monte el componente
  useEffect(() => {
    console.log('Montando el componente PendingOrders...');
    getPendingOrders();
  }, []);

  // Maneja la selección de un pedido
  const onOrderSelection = (orderId) => {
    console.log('Pedido seleccionado en función:', orderId); // Log para verificar qué pedido se seleccionó
    setSelectedOrder(orderId); // Actualiza el pedido seleccionado
    handleOrderSelection(orderId); // Notifica al componente padre sobre la selección
  };

  // Renderizado en caso de error
  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <p className="error-message">{error}</p>
      </div>
    );
  }

  // Renderizado durante la carga
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <p className="loading-message">Cargando pedidos pendientes...</p>
      </div>
    );
  }

  // Renderizado si no hay pedidos pendientes
  if (pendingOrders.length === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <img
          src="https://cdn.shopify.com/s/files/1/0611/0786/3712/files/vacio.png?v=1731023438"
          alt="Sin pedidos pendientes"
          style={{ width: '100px', marginBottom: '10px' }}
        />
        <p>
          No hay pedidos pendientes de realizar. Visita{' '}
          <a href="https://bteacher.es" target="_blank" rel="noopener noreferrer">
            bteacher.es
          </a>{' '}
          para más información.
        </p>
      </div>
    );
  }

  // Renderizado de la lista de pedidos pendientes
  return (
    <div className="pending-orders-list">
      {pendingOrders.map((order) => (
        <div
          key={order.order_id}
          className={`order-btn ${selectedOrder?.order_id === order.order_id ? 'selected' : ''}`} // Comprueba el objeto completo
          onClick={() => onOrderSelection(order)} // Pasa el objeto completo al seleccionar
        >
          <label className="container">
            <input type="checkbox" checked={selectedOrder?.order_id === order.order_id} readOnly />
            <div className="checkmark"></div>
          </label>
          <img src={order.product_flag} alt={order.product_name} className="product-flag" />
          <div className="order-info">{order.product_name}</div>
        </div>
      ))}
    </div>
  );

}

export default PendingOrders;
