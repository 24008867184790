// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-dashboard {
    margin: 20px;
  }
  
  .admin-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/AdminDashboard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".admin-dashboard {\n    margin: 20px;\n  }\n  \n  .admin-dashboard h1 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
